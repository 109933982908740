<div class="notif-wrapper">
  <div class="messages-title">Messages</div>
  <div class="account-header">
    <mat-list role="list">
      <!-- <mat-list-item>SBS3333S</mat-list-item> -->
      <mat-list-item
        *ngFor="let notif of notifList"
        role="listitem"
        matRipple
        (click)="notifEvent(notif)">
        <div class="notif-text">
          <div class="user-icon"><img src="assets/images/icon/bus.png" /></div>
          <div class="content">
            <div class="content-header">
              <span class="content-title">{{ notif.busRegNo }}</span>
              <span class="time">{{ notif?.messageTime }}</span>
            </div>
            <div class="content-text">{{ notif?.messageText }}</div>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
