import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { tap, map, retry } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

import { CommonService } from '../../shared/services/common.service';
import { setWsHeartbeat } from 'ws-heartbeat/client';
import {
  LogsData,
  mqttSendData,
  predefinedMessageData,
} from '../../shared/others/data-types';
import moment from 'moment';
import { VoipService } from '../../shared/services/voip.service';
// import { MatSnackBar } from '@angular/material';
import { VoipAlarmComponent } from './voip-alarm/voip-alarm.component';
import { AlarmService } from '../../shared/services/alarm.service';
import { HomeService } from '../../shared/services/home.service';
import { MqttType, WINDOW_NAME_MAIN } from '../../shared/others/constant';
import { WebSocketSubject } from 'rxjs/internal-compatibility';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'perfect-scrollbar-angular';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceWorkerService } from '../../shared/services/service-worker.service';
import { JitsiService } from '../../shared/services/jitsi.service';
import { MenuService } from '../../shared/services/menu.service';
import { PushNotificationService } from '../../shared/services/push-notification.service';
import { Location } from '@angular/common';

const audioSource = 'assets/sounds/alert.ogg';

@Component({
  selector: 'app-account-base',
  templateUrl: './account-base.component.html',
  styleUrls: ['./account-base.component.scss'],
})
export class AccountBaseComponent
  implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy
{
  // opened: boolean = false;
  // sidenav: ElementRef;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;
  connPingPong;
  allAlarms: any = [];
  voipData: any = {};
  isShowVoidPanel: boolean = false;
  socketPredefined$: WebSocketSubject<any>;
  socketOffRoute$: WebSocketSubject<any>;
  @ViewChild('chatPS', { static: true }) chatPS: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };
  isVoipBusy: boolean = false;
  isMenuShow: boolean = true;
  notificationActionsSubscription: Subscription;

  constructor(
    private _commonService: CommonService,
    private _voipService: VoipService,
    private _snackBar: MatSnackBar,
    private _alarmService: AlarmService,
    public _homeService: HomeService,
    private _cdr: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    private _jitsiService: JitsiService,
    public menuService: MenuService,
    public _pushNotificationService: PushNotificationService
  ) {}

  ngOnInit() {
    // this._commonService.onIdle();
    // var test1 = '2021-03-24 11: 24: 20.0';
    // console.log(moment(test1).format('YYYY-MM-DD HH:mm'));
    // var parseData = "{ routeId:41, offRouteStatus:1, driverId:BlockLogin, latitude:1.336507, longitude:103.744708, eventType:off-route-alert, eventSubType:null, eventStatus:null, eventSeverity:null, blockId:30410001, tripId:5403170683, timeStamp:2021-03-24 11:07:48.0}";
    // var routeId,offRouteStatus,driverId;
    //     var retStr = parseData.toString().replace(')', '').split(',');

    // for (const message of retStr) {
    //   let messageData = message.split(':');
    //   let indexName = messageData[0].replace('{', '').replace('}', '').trim();
    //   if(indexName === 'routeId'){
    //     routeId = messageData[1];
    //   }
    //   else if(indexName === 'offRouteStatus'){
    //     offRouteStatus = messageData[1];
    //   }
    //   else if(indexName === 'driverId'){
    //     driverId = messageData[1];
    //   }
    // }

    // var me = this;
    // setInterval(function() {
    //   let newMessage =  {
    //     'routeId': '41',
    //     'driverId': '4321',
    //     'latitude': '1',
    //     'longitude': '2',
    //     'eventType': 'Off Route',
    //     'eventSubType': 'sub type',
    //     'eventStatus': 'status',
    //     'eventSeverity': 'high',
    //     'blockId': '30410001',
    //     'tripId': '5321',
    //     'timeStamp': moment().unix(),
    //   };
    //   me._alarmService.allAlarm.next(newMessage);
    // },1000);

    // var testStr:any = '{routeId:41,offRouteStatus:1,driverId:2,latitude:0.0,longitude:0.0,eventType:3,eventSubType:5,"eventStatus:6,eventSeverity:7,blockId:8,tripId:9,timeStamp:1}'
    // var retStr = testStr.data.toString().replace('OffRoute(', '').replace(')', '').split(',');

    // for (const message of retStr) {
    //   let messageData = message.split('=');
    //   let indexName = messageData[0].trim();
    //   if(indexName === 'busRegNo'){
    //     busRegNo = messageData[1];
    //   }
    // }
    // console.log(this._homeService.setPredefinedData.length > 0);

    /**
     * Get the online/offline status from browser window
     */
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(e => {
        this.connectionStatusMessage = 'Back to online';
        this.connectionStatus = 'online';
        console.log('Online...');
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(e => {
        this.connectionStatusMessage =
          'Connection lost! You are not connected to internet';
        this.connectionStatus = 'offline';
        console.log('Offline...');
      })
    );

    // var me = this;
    // var urlAction = environment.nodeUrlLocal + 'ping';
    // //nodeUrlLocalWs nodeUrlLocal

    // this.connPingPong = setInterval(function() {
    //   me._commonService.commonGetFullUrlAction(urlAction).subscribe(
    //     (respData:any) => {
    //       console.log(respData);
    //     },)
    // }, 5000)
    // import * as WebSocket from "ws";
    // const ws = new WebSocket("ws://localhost:8000"); nodeUrlWs nodeUrlLocalWs
    // const ws = new WebSocket(environmentnodeUrlWs);

    // setWsHeartbeat(ws, '{"kind":"ping"}', {
    //   pingTimeout: 60000, // in 60 seconds, if no message accepted from server, close the connection.
    //   pingInterval: 1000, // every 25 seconds, send a ping message to the server.
    // });

    // this.allAlarms = this._alarmService.allAlarm;

    // this._alarmService.getAllAlarm().subscribe(alarm => {
    //   // console.log(alarm);
    //   this.allAlarms.push(alarm);
    // });

    // setTimeout(() => {
    //   this.allAlarms.unshift({
    //     'eventType': 'Message',
    //     'eventSeverity': 'Medium',
    //     'timeStamp': '1616240747',
    //     'from': 'Ali Rahmani'
    //   })
    // }, 2000);

    var url = this._router.url;
    var page = url.split('/')[2];

    var routeData = this._activatedRoute.snapshot.data;

    if (Object.values(routeData).find(x => x === page)) {
      console.log('menu ' + this.isMenuShow);
      this.isMenuShow = false;
    }
    window.name = WINDOW_NAME_MAIN;

    // this._activatedRoute.data.subscribe((data) => {
    // 	// const value = data['key']
    //       console.log(data);
    // });
    //   this._activatedRoute.data.subscribe(data => {
    //     console.log(data);
    // })
  }

  initServices() {
    this._jitsiService.init();
    this.initAlarmBroadcastChannel();
  }

  ngAfterViewChecked() {
    //your code to update the model
    this._cdr.detectChanges();
  }

  // handler when site opened from closed state
  handleEmergencyUrl() {
    // emergency handler
    const emergencyId = this._activatedRoute.snapshot.queryParamMap.get(
      'emergencyId'
    ) as string;
    // console.log("emergency init param: ", emergencyId);

    if (emergencyId) {
      // get agency
      const decodedToken: any = jwt_decode(
        localStorage.getItem('access_token')
      );
      const { agency } = decodedToken || {};
      const [bus, rid] = emergencyId.split(/[_]+/);

      const data = {
        id: rid,
        bus,
        rid,
        agency,
      };

      this._pushNotificationService.serviceEmergencyCall({ data });

      // this.emergencyList.push(emergencyData);
      // this._router.navigate([], {
      //   relativeTo: this._activatedRoute,
      //   queryParams: {},
      // });
      const urlTree = this._router.createUrlTree([], {
        queryParams: {},
        preserveFragment: true,
      });

      this._location.go(urlTree.toString());
    }
  }

  // toggleSide() {
  //   setTimeout(() => {

  //   this.opened = !this.opened;

  // }, 10);
  // }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all subscriptions to avoid memory leak
     */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    clearInterval(this.connPingPong);
    this.socketPredefined$?.unsubscribe?.();
    this.notificationActionsSubscription?.unsubscribe?.();
    // this.socketOffRoute$.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initPredefinedMessages();
      this.initOffRoute();
      // this._voipService.initVoip();
      this.initServices();
      this.handleEmergencyUrl();
    }, 500);
  }

  initIncomingData() {
    this.isShowVoidPanel = true;
    this.isVoipBusy = true;
    let testData = {
      busRegNo: '',
      stattus: 'calling',
    };
    this.voipData = testData;
  }

  voipHangUp() {
    this.voipData.msgEvent = 'drop';
    this.voipData.timeInSeconds = moment().unix();
    this._voipService.logVoip(this.voipData).then(dataResp => {
      this.isVoipBusy = false;
      this._voipService.doHangup();
    });
  }

  voipAnswer() {
    this.voipData.msgEvent = 'attend';
    this.voipData.timeInSeconds = moment().unix();
    this._voipService.logVoip(this.voipData).then(dataResp => {
      this.isVoipBusy = true;
      this._voipService.doAnswer();
    });
  }

  voipDecline() {
    this.voipData.msgEvent = 'reject';
    this.voipData.timeInSeconds = moment().unix();
    this._voipService.logVoip(this.voipData).then(dataResp => {
      this.isVoipBusy = false;
      this._voipService.doDecline();
    });
  }

  voipCall(voipData) {
    this.voipData.msgEvent = 'make';
    this.voipData.timeInSeconds = moment().unix();

    this._voipService.getVehicleSipNumber(voipData.busRegNo).subscribe(
      respSip => {
        if (respSip.sipId === '-') {
          this.isVoipBusy = false;
          this._snackBar.open(
            'No SIP number available on this vehicle ' + voipData.busRegNo,
            null,
            {
              duration: 5000,
            }
          );
          return false;
        } else if (respSip.sipId >= 1 && respSip.sipId <= 600) {
          console.log('account base ' + respSip.sipId);
          this._snackBar.open(
            'SIP number disabled ' + voipData.busRegNo,
            null,
            {
              duration: 5000,
            }
          );
          return false;
        } else {
          this.isShowVoidPanel = true;
          this.isVoipBusy = true;
          this._voipService.logVoip(this.voipData).then(dataResp => {
            this._voipService.doCall('sip:' + respSip.sipId + '@10.12.0.101');
          });
        }
      },
      err => {
        this._snackBar.open(
          'No SIP number available on this vehicle ' + voipData.busRegNo,
          null,
          {
            duration: 5000,
          }
        );
      }
    );
  }

  closeVoipPanel() {
    this.isShowVoidPanel = false;
  }

  alarmBroadcastChannel = new BroadcastChannel('alarm-channel');
  alarmBroadcast$;
  alarmBroadcastSubscription;
  initAlarmBroadcastChannel() {
    this.alarmBroadcast$ = fromEvent(
      this.alarmBroadcastChannel,
      'message'
    ).pipe(
      tap(console.log),
      map(event => event.data)
    );
    this.alarmBroadcastSubscription = this.alarmBroadcast$?.subscribe(data => {
      // console.log('broadcast: ', data);
      const { alarm, busAlarm } = data || {};
      if (alarm) {
        this.onCloseAlarm(alarm);
      }
      if (busAlarm) {
        this.onCloseAllAlarmsByBus(busAlarm);
      }
    });
  }

  // close single message based on timestamp and bus registration number
  onCloseAlarm(alarm, shouldBroadcast = false) {
    // console.log(alarm);
    // console.log(this._alarmService.getAllAlarm())
    var filteredItems = this.allAlarms.filter(
      item =>
        !(
          item.timeStamp === alarm.timeStamp && item.busRegNo === alarm.busRegNo
        )
    );
    // this._alarmService.allAlarm = filteredItems;
    this.allAlarms = filteredItems;
    if (shouldBroadcast) {
      this.alarmBroadcastChannel.postMessage({ alarm });
    }
  }

  // close all messages by bus registration number
  onCloseAllAlarmsByBus(alarm, shouldBroadcast = false) {
    const filteredItems = this.allAlarms.filter(
      item => item.busRegNo !== alarm.busRegNo
    );
    this.allAlarms = filteredItems;
    if (shouldBroadcast) {
      this.alarmBroadcastChannel.postMessage({ busAlarm: alarm });
    }
  }

  initPredefinedMessages() {
    const mqttSend: mqttSendData = {
      type: MqttType.ePredefinedMessages,
      busRegNo: '',
      service: '',
    };
    this.listenPredefinedMessages();
    this.mqttRequest(mqttSend);
  }

  testMessageInterval;
  prefeinedMessagesList: any = this._homeService.getMessageMessageList(); //localStorage.getItem('messsageList') === null ? [] : localStorage.getItem('messsageList');
  listenPredefinedMessages() {
    this.socketPredefined$ = new WebSocketSubject(environment.nodeUrlWs); //nodeUrlLocalWs nodeUrlWs

    //test data
    // let message = {
    //   "busRegNo": "busRegNo",
    //   "tripId": "tripId",
    //   "routeId": "123",
    //   "latitude": "latitude",
    //   "longitude": "longitude",
    //   "messageType": "messageType",
    //   "messageNo": "messageNo",
    //   "messageText": "messageText",
    //   "messageTime": moment(),
    //   "isBlinkRedYellow": true,
    //   "isVisible": false
    // }

    // this._homeService.setPredefinedData.push(message);
    // this.prefeinedMessagesList.push('busRegNo');

    // let text1 = {
    //   "busRegNo": 'busRegNo',
    //   'messageText': 'messageText111',
    //   'messageTime':  new Date(),
    //   "from": 'mqtt'
    // };

    // this.allAlarms.unshift({
    //   'eventType': 'Message',
    //   'eventSeverity': 'Medium',
    //   'timeStamp': text1.messageTime,
    //   'from': text1.busRegNo
    // })

    // setTimeout(() => {
    //   this._homeService.mqttMessageText.next(text1);
    // }, 1000);
    // var self = this;

    // setTimeout(() => {
    //   let count = 0;
    //   self.testMessageInterval = setInterval(function() {
    //     // console.log(count+' up');
    //     let text2 = {
    //       "busRegNo": 'busRegNo',
    //       'messageText': 'messageTex ' + count++,
    //       'messageTime':  new Date(),
    //       "from": 'mqtt'
    //     };
    //     // self.mqttMessageText['busRegNo'] = self.mqttMessageText['busRegNo'] === undefined ? [] : self.mqttMessageText['busRegNo'];
    //     // this.mqttMessageText['busRegNo'] = [];
    //     // self.mqttMessageText['busRegNo'].push(text2);
    //     self._homeService.mqttMessageText.next(text2);
    //   },2000);
    // //   this.setPredefinedData = [];
    // }, 1000);

    // setTimeout(() => {
    //   clearInterval(this.testMessageInterval);

    // let newMessage = {
    //   "busRegNo": message.busRegNo,
    //   'messageText': message.messageText,
    //   'messageTime': moment(message.messageTime).format('HH:mm a'),//moment.utc(messageTime).local().format(),
    //   "from": 'mqtt',
    //   'title': 'Message'
    // };

    // console.log(newMessage);

    // if(this.prefeinedMessagesList.indexOf(message.busRegNo) < 0) {
    //   this.prefeinedMessagesList.push(message.busRegNo);
    //   let messageData:any = {
    //     "busRegNo": message.busRegNo,
    //     "trip": message.tripId,
    //     // "driverId": driverId,
    //     "routeId": message.routeId,
    //     "latitude": message.latitude,
    //     "longitude": message.longitude,
    //     "messageType": message.messageType,
    //     "messageNo": message.messageNo,
    //     "messageText": message.messageText,
    //     "messageTime": message.messageTime,
    //     "from": 'mqtt',
    //     "isBlinkRedYellow": true,
    //     "isVisible": false
    //   }
    //   this._homeService.setPredefinedData.push(messageData);
    //   this._homeService.addMessagePanelCache(messageData);
    //   this._homeService.addMessageCache(newMessage);

    //   // localStorage.setItem('messsageList', this.prefeinedMessagesList);
    //   this._homeService.setMessageMessageList(message.busRegNo);
    // }

    // this.allAlarms.unshift({
    //   'eventType': 'Message',
    //   'timeStamp': moment(message.messageTime).format('YYYY-MM-DD HH:mm'),
    //   "busRegNo": message.busRegNo,
    //   'messageText': message.messageText,
    //   'title': 'Message'
    // })

    // setTimeout(() => {
    //   this._homeService.mqttMessageText.next(newMessage);
    // }, 100);

    // },5000);

    // console.log(this._homeService.setPredefinedData);
    //end test data
    // this.socketPredefined$.subscribe;
    this.socketPredefined$.pipe(retry()).subscribe(returnData => {
      var busRegNo: string,
        tripId: string,
        routeId: string,
        driverId: string,
        latitude: string,
        longitude: string,
        messageType: string,
        messageNo: string,
        messageText: string,
        messageTime: string = '';
      var retStr = returnData.data
        .toString()
        .replace('PreDefMessage(', '')
        .replace(')', '')
        .split(',');
      // console.log(retStr);
      for (const message of retStr) {
        let messageData = message.split('=');
        let indexName = messageData[0].trim();
        if (indexName === 'busRegNo') {
          busRegNo = messageData[1].trim();
        }
        if (indexName === 'tripId') {
          tripId = messageData[1];
        }
        if (indexName === 'routeId') {
          routeId = messageData[1];
        }
        if (indexName === 'driverId') {
          driverId = messageData[1];
        }
        if (indexName === 'latitude') {
          latitude = messageData[1];
        }
        if (indexName === 'longitude') {
          longitude = messageData[1];
        }
        if (indexName === 'messageType') {
          messageType = messageData[1];
        }
        if (indexName === 'messageNo') {
          messageNo = messageData[1];
        }
        if (indexName === 'messageText') {
          messageText = messageData[1];
        }
        if (indexName === 'messageTime') {
          messageTime = messageData[1];
        }
      }

      if (!busRegNo) {
        return false;
      }

      let newMessage = {
        busRegNo: busRegNo,
        messageText: messageText,
        messageTime: moment(messageTime).format('h:mm a'), //moment.utc(messageTime).local().format(),
        from: 'mqtt',
        title: 'Message',
      };
      // console.log(busRegNo);

      this._homeService.addMessageCache(newMessage);

      if (this.prefeinedMessagesList.indexOf(busRegNo) < 0) {
        this.prefeinedMessagesList.push(busRegNo);
        let message: predefinedMessageData = {
          busRegNo: busRegNo,
          trip: tripId,
          // "driverId": driverId,
          routeId: routeId,
          latitude: latitude,
          longitude: longitude,
          messageType: messageType,
          messageNo: messageNo,
          messageText: messageText,
          messageTime: messageTime,
          from: 'mqtt',
          isBlinkRedYellow: true,
          isVisible: false,
        };
        this._homeService.setPredefinedData.push(message);
        this._homeService.addMessagePanelCache(message);

        // localStorage.setItem('messsageList', this.prefeinedMessagesList);
        this._homeService.setMessageMessageList(busRegNo);
      }

      // console.log('message', messageTime, moment(messageTime).format('YYYY-MM-DD HH:mm:ss'));

      // check if message panel is open
      // this._homeService.setPredefinedData
      const busMessagePanel = this._homeService.setPredefinedData?.find(
        data => data?.busRegNo === busRegNo
      );
      const isMessagePanelOpen = busMessagePanel
        ? busMessagePanel?.isVisible ?? false
        : false;
      // console.log('message', busMessagePanel, isMessagePanelOpen);

      const alarmMessage = {
        eventType: 'Message',
        timeStamp: moment(messageTime).format('YYYY-MM-DD HH:mm:ss'),
        busRegNo: busRegNo,
        messageText: messageText,
        title: 'Message',
      };

      if (!isMessagePanelOpen) {
        this.allAlarms.unshift(alarmMessage);
      } else {
        // console.log('page visibility: ', window.document.visibilityState);
        // if message panel is open and tab is visible, close other tabs message notification
        if (window.document.visibilityState === 'visible') {
          setTimeout(() => this.onCloseAllAlarmsByBus(alarmMessage, true), 100);
        }
      }

      setTimeout(() => {
        this._homeService.mqttMessageText.next(newMessage);
      }, 100);
    });
  }

  public mqttRequest(data): void {
    const mqttSend: mqttSendData = {
      type: data.type,
      busRegNo: data.busRegNo === undefined ? '' : data.busRegNo,
      service: data.service === undefined ? '' : data.service,
    };
    const message = new mqttSendData(
      mqttSend.type,
      mqttSend.service,
      mqttSend.busRegNo
    );

    //const message = new Message(+data.service);
    // if(MqttType.eVehiclePosition === mqttSend.type) {
    //   this.socket$.next(message);
    // }
    if (MqttType.ePredefinedMessages === mqttSend.type) {
      this.socketPredefined$.next(message);
    } else {
      this.socketOffRoute$.next(message);
    }
  }

  closeMessagePanel(predefinedData) {
    this.prefeinedMessagesList.splice(
      this.prefeinedMessagesList.indexOf(predefinedData.busRegNo),
      1
    );
    var index = 0;
    for (const msg of this._homeService.setPredefinedData) {
      if (msg.busRegNo === predefinedData.busRegNo) {
        this._homeService.setPredefinedData.splice(
          this._homeService.setPredefinedData.indexOf(index),
          1
        );
      }
      index++;
    }
  }

  predefinedMessageZoom(data) {
    console.log(data);
    // this.viewAnimate.animate({
    //   center: fromLonLat([parseFloat(data.longitude), parseFloat(data.latitude)]),
    //   duration: 1000,
    //   zoom: 16
    // });
  }
  predefinedMessageClear(event) {
    console.log(event);
  }

  notifEvent(data) {
    // console.log(data);
    // console.log(this._homeService.setPredefinedData);
    for (const message of this._homeService.setPredefinedData) {
      if (message.busRegNo === data.busRegNo) {
        message.isVisible = true;
        // this.onCloseAlarm(data, true);
        this.onCloseAllAlarmsByBus(data, true);
      }
    }
  }

  initOffRoute() {
    const mqttSend: mqttSendData = {
      type: MqttType.eOffRoute,
      busRegNo: '',
      service: '',
    };
    this.listenOffRoute();
    this.mqttRequest(mqttSend);
  }

  listenOffRoute() {
    this.socketOffRoute$ = new WebSocketSubject(environment.nodeUrlWs);
    // this.socketOffRoute$ = new WebSocketSubject(environment.nodeUrlLocalWs);

    // this.socketPredefined$.subscribe;
    this.socketOffRoute$.pipe(retry()).subscribe(returnData => {
      // console.log(returnData);

      var routeId: string,
        driverId: string,
        latitude: string,
        longitude: string,
        eventType: string,
        eventSubType: string,
        eventStatus: string,
        eventSeverity: string,
        blockId: string,
        tripId: string,
        timeStamp: string,
        offRouteStatus: string,
        busRegNo: string;
      var retStr = returnData.data.toString().replace(')', '').split(',');

      for (const message of retStr) {
        let messageData = message.split(':');
        let indexName = messageData[0].replace('{', '').replace('}', '').trim();
        if (indexName === 'routeId') {
          routeId = messageData[1];
        } else if (indexName === 'driverId') {
          driverId = messageData[1];
        } else if (indexName === 'latitude') {
          latitude = messageData[1];
        } else if (indexName === 'longitude') {
          longitude = messageData[1];
        } else if (indexName === 'eventType') {
          eventType = messageData[1];
        } else if (indexName === 'eventSubType') {
          eventSubType = messageData[1];
        } else if (indexName === 'eventStatus') {
          eventStatus = messageData[1];
        } else if (indexName === 'eventSeverity') {
          eventSeverity = messageData[1];
        } else if (indexName === 'blockId') {
          blockId = messageData[1];
        } else if (indexName === 'tripId') {
          tripId = messageData[1];
        } else if (indexName === 'timeStamp') {
          timeStamp = messageData[1];
        } else if (indexName === 'offRouteStatus') {
          offRouteStatus = messageData[1];
        } else if (indexName === 'busRegNo') {
          busRegNo = messageData[1];
        }
      }

      if (routeId === undefined || offRouteStatus === '0') {
        return false;
      }

      let messageData = {
        routeId: routeId,
        driverId: driverId,
        latitude: latitude,
        longitude: longitude,
        eventType: eventType,
        eventSubType: eventSubType,
        eventStatus: eventStatus,
        eventSeverity: eventSeverity === 'null' ? 'high' : eventSeverity,
        blockId: blockId,
        tripId: tripId,
        timeStamp: moment(timeStamp).format('YYYY-MM-DD HH:mm:ss'),
        title: 'Off Route',
        busRegNo: busRegNo,
      };
      this.allAlarms.unshift(messageData);
      this._alarmService.allAlarm.next(messageData);
    });
  }

  showMessageList(busData) {
    var busValue = busData.value;
    if (this.prefeinedMessagesList.indexOf(busValue.busRegNo) < 0) {
      // console.log(busValue);
      let message: predefinedMessageData = {
        busRegNo: busValue.busRegNo,
        trip: busValue.trip,
        // "driverId": busValue.driverId,
        routeId: busValue.routeId,
        latitude: busValue.lat,
        longitude: busValue.lon,
        messageType: '',
        messageNo: '',
        messageText: '',
        messageTime: '',
        from: '',
        isBlinkRedYellow: false,
        isVisible: true,
      };
      this._homeService.setPredefinedData.push(message);
      this.prefeinedMessagesList.push(busValue.busRegNo);
    } else {
      for (const message of this._homeService.setPredefinedData) {
        if (message.busRegNo === busValue.busRegNo) {
          message.isVisible = true;
        }
      }
    }
  }
}
